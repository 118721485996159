"use client";

import { Box } from "@mui/material";
import { useMsal } from "@azure/msal-react";
import { useContext, useEffect, useReducer } from "react";
import { redirect } from "next/navigation";
import { AuthorizationContext } from "@components/AuthorizationContext";
import { AllRoles } from "@lib/authorizationRoles";
import { fetchPBIReportAsync } from "@lib/power-bi/powerbi-client-extension";
import { checkRole, convertEmbedReportConfigurationFromPowerBIEntity } from "@utils/helpers";
import { PBIEntityType } from "@definitions/index";
import { AppBreadcrumb } from "@components/Layout/AppBreadcrumb";
import myDashboardReducer, { MyDashboardState, MyDashboardActionType } from "@reducers/myDashboardReducer";
import { cacheEntitiesAndDatasetsAsync } from "@utils/powerBIEntityCategory";
import { EmbedReport, EmbedReportConfiguration, EmbedReportContainer } from "@components/PowerBI";
import { ReportName } from "pbi-config";
import { fetchPowerBIEntityByNameAndType } from "@lib/power-bi/powerbi-client-service";
export default function MyDashboardPage() {
  const {
    instance
  } = useMsal();
  const authenticatedContext = useContext(AuthorizationContext);
  if (!checkRole(authenticatedContext, AllRoles)) {
    redirect("/unauthorized");
  }
  ;
  const initialState: MyDashboardState = {
    isLoading: true,
    report: {} as EmbedReportConfiguration
  };
  const [myDashboardState, dispatch] = useReducer(myDashboardReducer, initialState);
  useEffect(() => {
    cacheEntitiesAndDatasetsAsync(authenticatedContext);
    if (myDashboardState.isLoading) {
      fetchPowerBIEntityByNameAndType(authenticatedContext.refreshToken(), ReportName.MyDashboard, PBIEntityType.Dashboard, (entity: any) => {
        const config = convertEmbedReportConfigurationFromPowerBIEntity(entity);
        fetchPBIReportAsync(instance, config, (params: any) => {
          dispatch({
            type: MyDashboardActionType.CHANGE_ALL_STATE,
            report: {
              ...config,
              ...params
            },
            isLoading: false
          });
        }).catch(console.error);
      }).catch(console.error);
    }
  }, [myDashboardState.isLoading]);
  return <Box sx={{
    height: "100%"
  }} data-sentry-element="Box" data-sentry-component="MyDashboardPage" data-sentry-source-file="page.tsx">
		<AppBreadcrumb links={[]} marginBottom={0} useAppPadding={true} data-sentry-element="AppBreadcrumb" data-sentry-source-file="page.tsx" />
		<EmbedReportContainer data-sentry-element="EmbedReportContainer" data-sentry-source-file="page.tsx">
			<EmbedReport config={myDashboardState.report} autoSaveFilters={true} data-sentry-element="EmbedReport" data-sentry-source-file="page.tsx" />
		</EmbedReportContainer>
	</Box>;
}