
import { EmbedReportConfiguration } from "@components/PowerBI";

enum MyDashboardActionType {
    CHANGE_IS_LOADING,
    CHANGE_REPORT_CONFIG,
    CHANGE_ALL_STATE,
}

interface MyDashboardState {
    isLoading: boolean;
    report: EmbedReportConfiguration;
}

interface MyDashboardAction {
    type: MyDashboardActionType | string;
    isLoading?: boolean;
    report?: EmbedReportConfiguration;
}

function myDashboardReducer(state: MyDashboardState, action: MyDashboardAction) {
    switch (action.type) {
        case MyDashboardActionType.CHANGE_IS_LOADING:
            return {
                ...state,
                isLoading: action.isLoading
            } as MyDashboardState;
        case MyDashboardActionType.CHANGE_REPORT_CONFIG:
            return {
                ...state,
                report: action.report
            } as MyDashboardState;
        case MyDashboardActionType.CHANGE_ALL_STATE: {
            return {
                ...state,
                ...action
            } as MyDashboardState;
        }
    }
    throw Error('Unknown action ' + action.type);
}

export type { MyDashboardState, MyDashboardAction }
export { MyDashboardActionType }
export default myDashboardReducer;